import { Component, input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
  ContextService,
  DateFilterCellComponent as DTFilter,
  FilterCellWrapperComponent,
  FilterInputDirective,
  FilterService,
  FocusableDirective,
  SizingOptionsService,
} from '@progress/kendo-angular-grid';
import { DatePickerComponent, DateTimePickerComponent } from '@progress/kendo-angular-dateinputs';
import { AsyncPipe, DatePipe } from '@angular/common';
import { DateFilterOperation } from '@core/enums/DateFilterOperation';
import { ButtonComponent } from '@progress/kendo-angular-buttons';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { filterClearIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'rc-date-time-filter',
  standalone: true,
  imports: [
    DateTimePickerComponent,
    AsyncPipe,
    DatePickerComponent,
    FilterCellWrapperComponent,
    ButtonComponent,
    FocusableDirective,
    FilterInputDirective,
    DatePipe,
  ],
  templateUrl: './date-filter.component.html',
  styleUrl: './date-filter.component.scss',
})
export class DateFilterComponent extends DTFilter implements OnInit, OnChanges {
  @ViewChild('dp') dp: DatePickerComponent;
  field = input.required<string>();
  filterOperator = input<DateFilterOperation>(DateFilterOperation.EXACT);
  showClearButton = false;
  value: Date | null;
  clearIcon = filterClearIcon;

  constructor(filterService: FilterService, ctx: ContextService, sizingOptionService: SizingOptionsService) {
    super(filterService, ctx, sizingOptionService);
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  setFilter(value: Date | null): void {
    if (this.filterOperator() === DateFilterOperation.BETWEEN && value) {
      this.applyBetweenFilter(value);
    } else {
      this.applyDateFilter(value);
    }
  }

  applyDateFilter(value: Date | null): void {
    let isoString = '';
    if (value) {
      isoString = this.formatToISOString(value);
    }
    this.applyFilter(
      value === null
        ? this.removeFilter(this.getFieldValue())
        : this.updateFilter({
            field: this.getFieldValue(),
            operator: 'eq',
            value: isoString,
          }),
    );
  }

  applyBetweenFilter(value: Date): void {
    const startDate = this.formatToISOString(value);
    const endDate = this.addOneDayAndFormat(value);

    this.applyFilter({
      logic: 'and',
      filters: [
        {
          field: `${this.field()}[after]`,
          operator: 'eq',
          value: startDate,
        },
        {
          field: `${this.field()}[before]`,
          operator: 'eq',
          value: endDate,
        },
      ],
    });
  }

  clearFilter() {
    this.value = null;
    this.dp.value = this.value;
    this.dp.focus();
    this.dp.blur();
    this.applyFilter(this.removeFilter(this.getFieldValue()));
  }

  protected override updateFilter(filter: FilterDescriptor): CompositeFilterDescriptor {
    this.showClearButton = true;
    return super.updateFilter(filter);
  }

  protected override removeFilter(field: string): CompositeFilterDescriptor {
    this.showClearButton = false;
    this.value = null;
    return super.removeFilter(field);
  }

  setValue(value: string) {
    this.dp.value = new Date(value);
    this.showClearButton = true;
    this.dp.focus();
    this.dp.blur();
  }

  getFieldValue(): string {
    if (this.filterOperator() !== DateFilterOperation.EXACT) {
      return `${this.field()}[${this.filterOperator()}]`;
    }
    return this.field();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filter']) {
      this.value = this.filterByField(this.getFieldValue())?.value;
    }
  }

  private formatToISOString(date: Date): string {
    const dateString = new Intl.DateTimeFormat('nl-BE').format(date);
    const [day, month, year] = dateString.split('/').map(Number);
    const formattedDate = new Date(year, month - 1, day);
    const isoDateTime = new Date(formattedDate.getTime() - formattedDate.getTimezoneOffset() * 60000).toISOString();
    return isoDateTime.split('T')[0];
  }

  private addOneDayAndFormat(date: Date): string {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    return this.formatToISOString(newDate);
  }
}
