/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { Component, Input, ContentChildren, QueryList, Directive, Optional, forwardRef, SkipSelf, Host, ContentChild, NgModule } from '@angular/core';
import { saveAs } from '@progress/kendo-file-saver';
import { IntlService, ExcelExporter, Workbook } from '@progress/kendo-ooxml';
export * from '@progress/kendo-ooxml';
import { toString } from '@progress/kendo-intl';
import * as i1 from '@progress/kendo-angular-l10n';
import { LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { validatePackage } from '@progress/kendo-licensing';
const compileTemplate = (templateRef, context, updateContext) => {
  let embeddedView = templateRef.createEmbeddedView(context);
  const result = data => {
    updateContext(context, data);
    embeddedView.detectChanges();
    return embeddedView.rootNodes.reduce((content, rootNode) => {
      return content + rootNode.textContent;
    }, '').trim();
  };
  result.destroy = () => {
    embeddedView.destroy();
    embeddedView = null;
  };
  return result;
};
const updateGroupHeaderContext = (context, data) => {
  context.$implicit = context.group = data;
  context.field = data.field;
  context.value = data.value;
  context.aggregates = data.aggregates;
};
const updateGroupFooterContext = (context, data) => {
  context.group = data.group;
  context.$implicit = context.aggregates = data;
};
const updateFooterContext = (context, data) => {
  context.aggregates = data.aggregates;
};
/**
 * @hidden
 */
const toExporterColumns = sourceColumns => {
  const exporterColumns = [];
  let columnIndex = 0;
  const addColumns = (columns, result, level) => {
    columns.forEach(column => {
      if (column.level === level) {
        const exporterColumn = new ExporterColumn(column, columnIndex);
        result.push(exporterColumn);
        if (column.children && column.children.some(c => c !== column)) {
          const children = exporterColumn.columns = [];
          addColumns(column.children, children, level + 1);
        } else {
          columnIndex++;
        }
      }
    });
  };
  addColumns(sourceColumns, exporterColumns, 0);
  return exporterColumns;
};
/**
 * @hidden
 */
const destroyColumns = columns => {
  if (columns) {
    columns.forEach(column => {
      column.destroy();
    });
  }
};
/**
 * @hidden
 */
class ExporterColumn {
  constructor(column, columnIndex) {
    this.title = column.title;
    this.field = column.field;
    this.hidden = column.hidden;
    this.locked = column.locked;
    this.width = column.width;
    this.headerCellOptions = column.headerCellOptions;
    this.cellOptions = column.cellOptions;
    this.groupHeaderCellOptions = column.groupHeaderCellOptions;
    this.groupFooterCellOptions = column.groupFooterCellOptions;
    this.footerCellOptions = column.footerCellOptions;
    if (column.footerTemplate) {
      this.footerTemplate = compileTemplate(column.footerTemplate.templateRef, {
        $implicit: column,
        column: column,
        columnIndex: columnIndex
      }, updateFooterContext);
    }
    if (column.groupFooterTemplate) {
      this.groupFooterTemplate = compileTemplate(column.groupFooterTemplate.templateRef, {
        column: column,
        field: column.field
      }, updateGroupFooterContext);
    }
    if (column.groupHeaderTemplate) {
      this.groupHeaderTemplate = compileTemplate(column.groupHeaderTemplate.templateRef, {}, updateGroupHeaderContext);
    }
    if (column.groupHeaderColumnTemplate) {
      this.groupHeaderColumnTemplate = compileTemplate(column.groupHeaderColumnTemplate.templateRef, {}, updateGroupHeaderContext);
    }
  }
  destroy() {
    if (this.footerTemplate) {
      this.footerTemplate.destroy();
    }
    if (this.groupFooterTemplate) {
      this.groupFooterTemplate.destroy();
    }
    if (this.groupHeaderTemplate) {
      this.groupHeaderTemplate.destroy();
    }
    if (this.groupHeaderColumnTemplate) {
      this.groupHeaderColumnTemplate.destroy();
    }
    destroyColumns(this.columns);
  }
}
IntlService.register({
  toString
});
/**
 *
 * @hidden
 */
const workbookOptions = options => {
  const columns = toExporterColumns(options.columns);
  const exporter = new ExcelExporter({
    columns: columns,
    data: options.data,
    filterable: options.filterable,
    groups: options.group,
    paddingCellOptions: options.paddingCellOptions,
    headerPaddingCellOptions: options.headerPaddingCellOptions,
    collapsible: options.collapsible,
    hierarchy: options.hierarchy,
    aggregates: options.aggregates
  });
  const result = exporter.workbook();
  result.creator = options.creator;
  result.date = options.date;
  result.rtl = options.rtl;
  destroyColumns(columns);
  return result;
};
/**
 * @hidden
 */
const toDataURL = options => {
  const workbook = new Workbook(options);
  return workbook.toDataURL();
};
/**
 * @hidden
 */
const isWorkbookOptions = value => {
  return value && value.sheets;
};

/**
 * @hidden
 */
class ColumnBase {
  constructor(parent) {
    this.parent = parent;
  }
  /**
   * @hidden
   */
  get level() {
    return this.parent ? this.parent.level + 1 : 0;
  }
}
ColumnBase.ɵfac = function ColumnBase_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ColumnBase)(i0.ɵɵdirectiveInject(ColumnBase));
};
ColumnBase.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ColumnBase,
  selectors: [["ng-component"]],
  contentQueries: function ColumnBase_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ColumnBase, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  inputs: {
    title: "title",
    width: "width",
    locked: "locked",
    hidden: "hidden",
    headerCellOptions: "headerCellOptions"
  },
  decls: 0,
  vars: 0,
  template: function ColumnBase_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColumnBase, [{
    type: Component,
    args: [{
      template: ''
    }]
  }], function () {
    return [{
      type: ColumnBase
    }];
  }, {
    title: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    locked: [{
      type: Input
    }],
    hidden: [{
      type: Input
    }],
    headerCellOptions: [{
      type: Input
    }],
    children: [{
      type: ContentChildren,
      args: [ColumnBase]
    }]
  });
})();

/**
 * @hidden
 */
const packageMetadata = {
  name: '@progress/kendo-angular-excel-export',
  productName: 'Kendo UI for Angular',
  productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
  publishDate: 1724741803,
  version: '16.8.0',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};

// eslint-disable max-line-length
/**
 * Represents the [Kendo UI Excel Export component for Angular]({% slug overview_excelexport %}).
 * Configures the settings for the Excel export of the Kendo UI Grid.
 */
class ExcelExportComponent {
  constructor(localization, zone) {
    this.localization = localization;
    this.zone = zone;
    /**
     * Specifies the name of the file that is exported to Excel.
     * @default "Export.xlsx"
     */
    this.fileName = 'Export.xlsx';
    /**
     * @hidden
     */
    this.columns = new QueryList();
    validatePackage(packageMetadata);
    this.saveFile = this.saveFile.bind(this);
  }
  /**
   * Saves the data to Excel.
   *
   * @param exportData - An optional parameter. Can be the data that will be exported or [`WorkbookOptions`]({% slug api_excel-export_workbookoptions %}).
   */
  save(exportData) {
    this.toDataURL(exportData).then(this.saveFile);
  }
  /**
   * Based on the specified columns and data, returns
   * [`WorkbookOptions`]({% slug api_excel-export_workbookoptions %})
   * ([see example]({% slug customrowsandcells_excelexport %})).
   *
   * @param exportData - The optional data to be exported.
   * @returns {WorkbookOptions} - The workbook options.
   */
  workbookOptions(exportData) {
    const currentData = this.getExportData(exportData);
    const options = workbookOptions({
      columns: this.columns,
      data: currentData.data,
      group: currentData.group,
      filterable: this.filterable,
      creator: this.creator,
      date: this.date,
      rtl: this.localization.rtl,
      paddingCellOptions: this.paddingCellOptions,
      headerPaddingCellOptions: this.headerPaddingCellOptions,
      collapsible: this.collapsible
    });
    return options;
  }
  /**
   * Returns a promise which will be resolved with the file data URI
   * ([see example]({% slug filesaving_excelexport %})).
   *
   * @param exportData - The optional data or [`WorkbookOptions`]({% slug api_excel-export_workbookoptions %}) that will be used to generate the data URI.
   * @returns {Promise<string>} - The promise that will be resolved by the file data URI.
   */
  toDataURL(exportData) {
    const options = isWorkbookOptions(exportData) ? exportData : this.workbookOptions(exportData);
    return this.zone.runOutsideAngular(() => toDataURL(options));
  }
  getExportData(exportData) {
    let result;
    if (exportData) {
      if (Array.isArray(exportData)) {
        result = {
          data: exportData
        };
      } else {
        result = exportData;
      }
    } else {
      result = {
        data: this.data,
        group: this.group
      };
    }
    return result;
  }
  saveFile(dataURL) {
    saveAs(dataURL, this.fileName, {
      forceProxy: this.forceProxy,
      proxyURL: this.proxyURL
    });
  }
}
ExcelExportComponent.ɵfac = function ExcelExportComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ExcelExportComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.NgZone));
};
ExcelExportComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ExcelExportComponent,
  selectors: [["kendo-excelexport"]],
  contentQueries: function ExcelExportComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ColumnBase, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.columns = _t);
    }
  },
  inputs: {
    fileName: "fileName",
    filterable: "filterable",
    collapsible: "collapsible",
    creator: "creator",
    date: "date",
    forceProxy: "forceProxy",
    proxyURL: "proxyURL",
    data: "data",
    group: "group",
    paddingCellOptions: "paddingCellOptions",
    headerPaddingCellOptions: "headerPaddingCellOptions"
  },
  exportAs: ["kendoExcelExport"],
  standalone: true,
  features: [i0.ɵɵProvidersFeature([LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.excelexport'
  }]), i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function ExcelExportComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ExcelExportComponent, [{
    type: Component,
    args: [{
      exportAs: 'kendoExcelExport',
      selector: 'kendo-excelexport',
      providers: [LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.excelexport'
      }],
      template: ``,
      standalone: true
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: i0.NgZone
    }];
  }, {
    fileName: [{
      type: Input
    }],
    filterable: [{
      type: Input
    }],
    collapsible: [{
      type: Input
    }],
    creator: [{
      type: Input
    }],
    date: [{
      type: Input
    }],
    forceProxy: [{
      type: Input
    }],
    proxyURL: [{
      type: Input
    }],
    data: [{
      type: Input
    }],
    group: [{
      type: Input
    }],
    paddingCellOptions: [{
      type: Input
    }],
    headerPaddingCellOptions: [{
      type: Input
    }],
    columns: [{
      type: ContentChildren,
      args: [ColumnBase, {
        descendants: true
      }]
    }]
  });
})();

/**
 * Represents the footer cell template of the Excel Export column component
 * ([see example]({% slug columns_excel-export %}#toc-footer-template)).
 * Enables you to customize the footer cell of the column.
 */
class FooterTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
FooterTemplateDirective.ɵfac = function FooterTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FooterTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
FooterTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: FooterTemplateDirective,
  selectors: [["", "kendoExcelExportFooterTemplate", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FooterTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoExcelExportFooterTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Represents the group header cell template of the Excel Export column component
 * ([see example]({% slug columns_excel-export %}#toc-group-header-template)).
 * Enables you to customize the content of the group header item.
 */
class GroupHeaderTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
GroupHeaderTemplateDirective.ɵfac = function GroupHeaderTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GroupHeaderTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
GroupHeaderTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: GroupHeaderTemplateDirective,
  selectors: [["", "kendoExcelExportGroupHeaderTemplate", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GroupHeaderTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoExcelExportGroupHeaderTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Represents the group header column template of the Excel Export column component
 * ([see example]({% slug columns_excel-export %}#toc-group-header-column-template)).
 */
class GroupHeaderColumnTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
GroupHeaderColumnTemplateDirective.ɵfac = function GroupHeaderColumnTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GroupHeaderColumnTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
GroupHeaderColumnTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: GroupHeaderColumnTemplateDirective,
  selectors: [["", "kendoExcelExportGroupHeaderColumnTemplate", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GroupHeaderColumnTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoExcelExportGroupHeaderColumnTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Represents the group footer cell template of the Excel Export column component
 * ([see example]({% slug columns_excel-export %}#toc-group-footer-template)).
 * Enables you to customize the group footer cell of the column.
 */
class GroupFooterTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
GroupFooterTemplateDirective.ɵfac = function GroupFooterTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GroupFooterTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
GroupFooterTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: GroupFooterTemplateDirective,
  selectors: [["", "kendoExcelExportGroupFooterTemplate", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GroupFooterTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoExcelExportGroupFooterTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Represents the columns of the Kendo UI Excel Export component for Angular.
 */
class ColumnComponent extends ColumnBase {
  constructor(parent) {
    super(parent);
  }
}
ColumnComponent.ɵfac = function ColumnComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ColumnComponent)(i0.ɵɵdirectiveInject(ColumnBase, 13));
};
ColumnComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ColumnComponent,
  selectors: [["kendo-excelexport-column"]],
  contentQueries: function ColumnComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, GroupHeaderTemplateDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, GroupHeaderColumnTemplateDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, GroupFooterTemplateDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, FooterTemplateDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.groupHeaderTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.groupHeaderColumnTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.groupFooterTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.footerTemplate = _t.first);
    }
  },
  inputs: {
    field: "field",
    cellOptions: "cellOptions",
    groupHeaderCellOptions: "groupHeaderCellOptions",
    groupFooterCellOptions: "groupFooterCellOptions",
    footerCellOptions: "footerCellOptions"
  },
  standalone: true,
  features: [i0.ɵɵProvidersFeature([{
    provide: ColumnBase,
    useExisting: forwardRef(() => ColumnComponent)
  }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function ColumnComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColumnComponent, [{
    type: Component,
    args: [{
      providers: [{
        provide: ColumnBase,
        useExisting: forwardRef(() => ColumnComponent)
      }],
      selector: 'kendo-excelexport-column',
      template: ``,
      standalone: true
    }]
  }], function () {
    return [{
      type: ColumnBase,
      decorators: [{
        type: SkipSelf
      }, {
        type: Host
      }, {
        type: Optional
      }]
    }];
  }, {
    field: [{
      type: Input
    }],
    cellOptions: [{
      type: Input
    }],
    groupHeaderCellOptions: [{
      type: Input
    }],
    groupFooterCellOptions: [{
      type: Input
    }],
    footerCellOptions: [{
      type: Input
    }],
    groupHeaderTemplate: [{
      type: ContentChild,
      args: [GroupHeaderTemplateDirective, {
        static: false
      }]
    }],
    groupHeaderColumnTemplate: [{
      type: ContentChild,
      args: [GroupHeaderColumnTemplateDirective, {
        static: false
      }]
    }],
    groupFooterTemplate: [{
      type: ContentChild,
      args: [GroupFooterTemplateDirective, {
        static: false
      }]
    }],
    footerTemplate: [{
      type: ContentChild,
      args: [FooterTemplateDirective, {
        static: false
      }]
    }]
  });
})();

/**
 * Represents the column group component of the Kendo UI Excel Export component.
 */
class ColumnGroupComponent extends ColumnBase {
  constructor(parent) {
    super(parent);
    this.parent = parent;
  }
}
ColumnGroupComponent.ɵfac = function ColumnGroupComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ColumnGroupComponent)(i0.ɵɵdirectiveInject(ColumnBase, 13));
};
ColumnGroupComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ColumnGroupComponent,
  selectors: [["kendo-excelexport-column-group"]],
  standalone: true,
  features: [i0.ɵɵProvidersFeature([{
    provide: ColumnBase,
    useExisting: forwardRef(() => ColumnGroupComponent)
  }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function ColumnGroupComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColumnGroupComponent, [{
    type: Component,
    args: [{
      providers: [{
        provide: ColumnBase,
        useExisting: forwardRef(() => ColumnGroupComponent)
      }],
      selector: 'kendo-excelexport-column-group',
      template: ``,
      standalone: true
    }]
  }], function () {
    return [{
      type: ColumnBase,
      decorators: [{
        type: SkipSelf
      }, {
        type: Host
      }, {
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Utility array that contains all `@progress/kendo-angular-excel-export` related components and directives
 */
const KENDO_EXCELEXPORT = [ExcelExportComponent, ColumnComponent, ColumnGroupComponent, FooterTemplateDirective, GroupFooterTemplateDirective, GroupHeaderColumnTemplateDirective, GroupHeaderTemplateDirective];

// IMPORTANT: NgModule export kept for backwards compatibility
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Excel Export component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the ExcelExportModule module
 * import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, ExcelExportModule], // import ExcelExportModule module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class ExcelExportModule {}
ExcelExportModule.ɵfac = function ExcelExportModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ExcelExportModule)();
};
ExcelExportModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ExcelExportModule
});
ExcelExportModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [ExcelExportComponent, ColumnComponent, ColumnGroupComponent]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ExcelExportModule, [{
    type: NgModule,
    args: [{
      imports: [...KENDO_EXCELEXPORT],
      exports: [...KENDO_EXCELEXPORT]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ColumnBase, ColumnComponent, ColumnGroupComponent, ExcelExportComponent, ExcelExportModule, FooterTemplateDirective, GroupFooterTemplateDirective, GroupHeaderColumnTemplateDirective, GroupHeaderTemplateDirective, KENDO_EXCELEXPORT, isWorkbookOptions, toDataURL, workbookOptions };