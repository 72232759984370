<div class="rc-combobox-filter k-filtercell-wrapper">
  @let valueList = valueListObservable$() | async;
  <kendo-combobox
    #filterComponent
    [data]="getData(valueList)"
    [kendoDropDownFilter]="filterSettings"
    [filterable]="true"
    [textField]="textField()"
    [valueField]="valueField()"
    (valueChange)="setFilter($event)">
  </kendo-combobox>
</div>
