import { HttpParams } from '@angular/common/http';
import { ListItemModel } from '@progress/kendo-angular-buttons';
import { CellClickEvent, ColumnComponent, GridComponent, PagerType } from '@progress/kendo-angular-grid';
import { FilterDescriptor, State, toDataSourceRequest } from '@progress/kendo-data-query';
import { BehaviorSubject } from 'rxjs';
import { Component, inject, Inject, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DateFilterComponent } from '@shared/components/date-time-filter/date-filter.component';
import { ComboboxFilterComponent } from '@shared/components/combocbox-filter/combobox-filter.component';
import { DateFilterOperation } from '@core/enums/DateFilterOperation';

@Component({
  template: '',
})
export abstract class KendoGridComponent {
  #router = inject(Router);
  #activatedRoute = inject(ActivatedRoute);

  isLoadingGridData$ = new BehaviorSubject<boolean>(true);

  @ViewChildren('filterComponent') filterComponents!: QueryList<ComboboxFilterComponent<any> | DateFilterComponent>;

  gridState: State;

  readonly GRID_CONFIGURATION = GRID_CONFIGURATION;

  protected constructor(@Inject(Object) customGridConfiguration?: { defaultPageSize?: number }) {
    this.gridState = {
      filter: {
        logic: 'and',
        filters: [],
      },
      sort: [],
      skip: 0,
      take: customGridConfiguration?.defaultPageSize ?? this.GRID_CONFIGURATION.defaultPageSize,
    };
  }

  abstract onDataStateChange(state: State, shouldClearState?: boolean): void;

  hasClickedGridRowButtons(event: CellClickEvent): boolean {
    return !!event.originalEvent.target.className.includes('k-button');
  }

  getPaginationParamsFromGridState(params: HttpParams): HttpParams {
    const stateParameters = toDataSourceRequest(this.gridState);

    params = params.append('page', stateParameters.page);
    params = params.append('itemsPerPage', stateParameters.pageSize);

    return params;
  }

  getSortingQueryParamsFromGridState(params: HttpParams): HttpParams {
    if (this.gridState.sort?.length) {
      this.gridState.sort.forEach((sort) => {
        params = params.append(`order[${sort.field}]`, sort.dir ?? '');
      });
    }

    return params;
  }

  getFilterQueryParamsFromGridState(params: HttpParams): HttpParams {
    const filters: FilterDescriptor[] | undefined = this.gridState.filter?.filters as FilterDescriptor[];

    if (filters === undefined) {
      return params;
    }

    filters
      .filter((filter) => filter.field !== undefined)
      .forEach((filter) => {
        params = params.append(filter.field?.toString() ?? '', filter.value);
      });

    return params;
  }

  convertGridStateToQueryParams(): HttpParams {
    let queryParams = new HttpParams();

    queryParams = this.getPaginationParamsFromGridState(queryParams);
    queryParams = this.getSortingQueryParamsFromGridState(queryParams);
    queryParams = this.getFilterQueryParamsFromGridState(queryParams);

    return queryParams;
  }

  addGridStateToQueryParams(): void {
    this.#router.navigate([], {
      relativeTo: this.#activatedRoute,
      queryParams: this.getQueryParamsAsObject(),
      replaceUrl: true,
    });
  }

  getQueryParamsAsObject(): Record<string, string> {
    return this.convertGridStateToQueryParams()
      .keys()
      .reduce(
        (acc, key) => {
          acc[key] = this.convertGridStateToQueryParams().get(key) as string;
          return acc;
        },
        {} as Record<string, string>,
      );
  }

  getColumnNames(grid: GridComponent): string[] {
    return grid.columnList
      .toArray()
      .map((column) => column as ColumnComponent)
      .filter((column) => {
        return column.filterable === true && column.field;
      })
      .map((column) => column.field);
  }

  convertQueryParamToAppropriateType(param: string): number | boolean | string {
    if (!isNaN(Number(param))) {
      return Number(param);
    }

    if (param === 'true' || param === 'false') {
      return param === 'true';
    }

    return param;
  }

  getPaginationParamsFromQueryParams(gridState: State): State {
    const queryParams = this.#activatedRoute.snapshot.queryParams;

    if (queryParams['itemsPerPage']) {
      gridState.take = Number(queryParams['itemsPerPage']);
    }

    if (queryParams['page']) {
      gridState.skip = Number(queryParams['page'] - 1) * (gridState.take ?? 0);
    }

    return gridState;
  }

  getSortingParamsFromQueryParams(gridState: State): State {
    const queryParams = this.#activatedRoute.snapshot.queryParams;

    const sortingQueryParams = Object.keys(queryParams).filter((key) => key.startsWith('order'));

    if (sortingQueryParams.length) {
      gridState.sort = sortingQueryParams.map((key) => {
        return {
          field: key.replace('order[', '').replace(']', ''),
          dir: queryParams[key],
        };
      });
    }

    return gridState;
  }

  getFilterParamsFromQueryParams(gridState: State, grid: GridComponent): State {
    const queryParams = this.#activatedRoute.snapshot.queryParams;
    const filterQueryParams = Object.keys(queryParams).filter(
      (key) =>
        !key.startsWith('order') &&
        key !== 'page' &&
        key !== 'itemsPerPage' &&
        this.getColumnNames(grid).includes(key.split('[')[0]),
    );

    if (filterQueryParams.length) {
      const filtersFromQueryParams = filterQueryParams.map((key) => {
        this.setFilters(key, queryParams[key]);
        return {
          field: key,
          operator: 'eq',
          value: this.convertQueryParamToAppropriateType(queryParams[key]),
        };
      });

      const originalFilters = gridState.filter?.filters as FilterDescriptor[];
      const originalFiltersWithoutFiltersFromQueryParams = originalFilters.filter(
        (filter) => !filterQueryParams.includes(filter.field as string),
      );

      gridState.filter = {
        logic: 'and',
        filters: [...originalFiltersWithoutFiltersFromQueryParams, ...filtersFromQueryParams],
      };
    }

    return gridState;
  }

  convertQueryParamsToGridState(grid: GridComponent, originalGridState: State): State {
    let gridState = { ...originalGridState };

    gridState = this.getPaginationParamsFromQueryParams(gridState);
    gridState = this.getSortingParamsFromQueryParams(gridState);
    gridState = this.getFilterParamsFromQueryParams(gridState, grid);

    return gridState;
  }

  setFilters(key: string, value: string) {
    const comboboxes = this.filterComponents.filter((component) => component instanceof ComboboxFilterComponent);
    const dateFilters = this.filterComponents.filter((component) => component instanceof DateFilterComponent);
    comboboxes.find((component) => component.field() === key)?.setValue(value);
    dateFilters.forEach((dateFilter: DateFilterComponent) => {
      if (dateFilter.filterOperator() === DateFilterOperation.EXACT) {
        if (dateFilter.field() === key) {
          dateFilter.setValue(value);
        }
      } else if (
        dateFilter.filterOperator() === DateFilterOperation.BETWEEN &&
        `${dateFilter.field()}[${DateFilterOperation.AFTER}]` === key
      ) {
        dateFilter.setValue(value);
      } else {
        if (`${dateFilter.field()}[${dateFilter.filterOperator()}]` === key) {
          dateFilter.setValue(value);
        }
      }
    });
  }

  clearFilters(updateData$: BehaviorSubject<void>) {
    this.filterComponents.forEach((component) => {
      component.value = null;
      component.clearFilter();
    });

    this.gridState.filter = {
      logic: 'and',
      filters: [],
    };
    updateData$.next();
  }
}

export interface ActionListItemModel<T = string> extends ListItemModel {
  action: T;
  hideExpression?: (item: unknown) => boolean;
  disabledExpression?: (item: unknown) => boolean;
}

export interface HasHideExpression {
  hideExpression?: (item: unknown) => boolean;
}

export const GRID_CONFIGURATION = {
  defaultPageSize: 10,
  pagination: true,
  pageable: {
    refresh: true,
    buttonCount: 3,
    info: true,
    type: 'numeric' as PagerType,
    pageSizes: [5, 10, 20, 50],
    previousNext: true,
  },
};
